@keyframes bgcolor {
    0% {
        background-color: #343a40
    }
    30% {
        background-color: #007bff
    }
    60% {
        background-color: #343a40
    }
    90% {
        background-color: #007bff
    }
    100% {
        background-color: #343a40
    }
}

.dynamicBackground {
    -webkit-animation: bgcolor 20s infinite;
    animation: bgcolor 7s infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}




.mobileContainer {
    position: relative;
    display: inline-block;
}

.foreground-image {
    position: relative;
    z-index: 2;
    width: 100%;
}

.background-image {
    border-radius: 30px;
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 97%;
    object-fit: cover;
    z-index: 1;
    filter: brightness(45%);
}

.top-content {
    flex-direction: row;
    justify-content: space-between;
    width: 93%;
    border-radius: 15px 15px 0 0;

    font-size: 0.8em;
    font-weight: 300;
    padding-inline: 10px;
    padding-top: 8px;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 66;
}


.foreground-content {
    position: absolute;
    top: 26px;
    left: -1px;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}



.pulso {
    background: black;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    /* height: 45px;
    width: 45px;
    padding: 5px; */
    transform: scale(1);
    animation: pulse-black 4s infinite;
    /* border-radius: 50%; */
}
.pulso.azul {
    /* background: rgb(7, 65, 121,1); */
    /* box-shadow: 0 0 0 0 rgba(7, 65, 121, 1); */
    box-shadow: 0 6px 10px 0 rgba(40, 167, 90, 0.8), 0 8px 22px 0 rgba(40, 167, 90, 0.2);
    animation: pulso-azul 3s infinite;
}
@keyframes pulso-azul {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(40, 167, 90, 0.7);
    }
    70% {
        transform: scale(1.03);
        box-shadow: 0 0 0 10px rgba(217, 120, 51, 0);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(5, 223, 172, 0);
    }
}
