/* body {
    background-color: #f5f5f5;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
} */


.colorNaranja {
    color: #ff8f48;
}


.colorTurquesa {
    color: #66b8ff
}

.fondoTurquesa {
    background-color: #66b8ff;
}


.input-with-icon {
    position: relative;
}

.input-with-icon input[type="text"] {
    padding-right: 30px; /* Espacio para el icono */
}

.input-with-icon .icon {
    position: absolute;
    top: 50%;
    right: 10px; /* Puedes ajustar la posición del icono */
    transform: translateY(-50%);
}


.footer {
    background-color: #171622;
    color: #fff;
    padding: 2px;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }