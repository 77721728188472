.map-wrap-full {
    position: relative;
    width: 100%;
    height: 450px;
    /* calculate height of the screen minus the heading */
}

.map-wrap-compact {
    position: relative;
    width: 100%;
    height: 270px;
    /* calculate height of the screen minus the heading */
}

.map {
    position: absolute;
    width: 100%;
    height: 100%;
}